import React, { useRef, useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { Place } from './add-new-service-modal/types';

const libraries: ("places")[] = ["places"];

interface AutocompleteInputProps {
  initialPlace?: Place;
  onPlaceSelected: (place: { address: string, lat: number, lng: number }) => void;
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({ initialPlace, onPlaceSelected }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAnXic_HGy2SCt_Isj7FdbIIkNqcueC5X0",
    libraries,
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [suggestions, setSuggestions] = useState<{ description: string, place_id: string }[]>([]);

  useEffect(() => {
    if (isLoaded && inputRef.current && !autocomplete) {
      const autocompleteInstance = new google.maps.places.Autocomplete(inputRef.current!);
      setAutocomplete(autocompleteInstance);

      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();
        if (place && place.formatted_address && place.geometry && place.place_id)  {
          const location = place.geometry.location;
          onPlaceSelected({
            address: place.formatted_address,
            lat: location!.lat(),
            lng: location!.lng(),
          });
          setSuggestions([{ description: place.formatted_address, place_id: place.place_id }]);
        }
      });

      inputRef.current.addEventListener('input', () => {
        const inputValue = inputRef.current!.value;
        if (inputValue === '') {
          setSuggestions([]);
          return;
        }

        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions.map(prediction => ({
              description: prediction.description,
              place_id: prediction.place_id,
            })));
          } else {
            setSuggestions([]);
          }
        });
      });
    }
  }, [isLoaded, inputRef.current]);

  useEffect(() => {
    // If initialPlace is provided, set the input value
    if (initialPlace && inputRef.current) {
      inputRef.current.value = initialPlace.address;
    }
  }, [initialPlace, inputRef.current]);

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        ref={inputRef}
        placeholder="Enter a location"
        style={{ width: '100%', padding: '8px', fontSize: '16px', boxSizing: 'border-box' }}
      />
      {suggestions.length > 0 && (
        <ul style={{
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: '#fff',
          zIndex: 1000,
          maxHeight: '200px',
          overflowY: 'auto'
        }}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
              onMouseDown={(e) => {
                e.preventDefault();
                // Prevent the input field from losing focus
              }}
              onClick={() => {
                const placesService = new google.maps.places.PlacesService(document.createElement('div'));
                placesService.getDetails({ placeId: suggestion.place_id }, (place, status) => {
                  if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                    const location = place.geometry?.location;
                    if (location) {
                      onPlaceSelected({
                        address: place.formatted_address!,
                        lat: location.lat(),
                        lng: location.lng(),
                      });
                      if (inputRef.current) {
                        inputRef.current.value = place.formatted_address!;
                      }
                      setSuggestions([]);
                    }
                  }
                });
              }}
            >
                 {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteInput;