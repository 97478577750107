import logo from "../../assests/images/logo_without_bg.png";

const Loading = () => {
  return (
    <div style={{ position: "relative" }}>
      <h2
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src={logo} width="80px" />
      </h2>
    </div>
  );
};

export default Loading;
