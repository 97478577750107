import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../components/navbar";
import "./style.css";
import profileImg from "../../assests/images/profile_img.png";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { GoDash } from "react-icons/go";
import { IoAdd } from "react-icons/io5";
import serviceImg from "../../assests/images/other-products-img.png";
import axios from "axios";
import { PROD_URL } from "../../helpers/constants";
import { UserData } from "../signup-login/types";
import { toast } from "react-toastify";
import ServiceProvider from "../../providers/serviceProvider";
import StripeProvider from "../../providers/stripeProvider";
import Footer from "../../components/footer";
import { LockOpen } from "@mui/icons-material";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function ProfileSettings() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [basicInfoDisabled, setBasicInfoDisabled] = useState(true);
  const [passwordDisabled, setPasswordDisabled] = useState(true);
  const [idToken, setIdToken] = useState("");
  const [profileData, setProfileData] = useState<UserData | null>(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [country, setCountry] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [stripeAccountId, setStripeAccountId] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [stripeButtonEnable, setStripeButtonEnable] = useState(false);

  useEffect(() => {
    initFunction();
  }, [authContext]);

  async function initFunction() {
    const user = authContext?.user;
    if (user) {
      const retrievedIdToken = await user.getIdToken();

      if (retrievedIdToken) {
        setIdToken(retrievedIdToken);
        const { code } = getUrlParams();
        if (code) {
          connectStripeAccount(retrievedIdToken, code)
            .then(() => {
              clearUrlParams(); // Clear URL params after status change
            })
            .catch((error) => {
              console.error(
                "Error occurred while changing event status:",
                error
              );
            });
        }
        const storedUserData =
          JSON.parse(localStorage.getItem("artistCubeUserData") || "null") ||
          null;

        console.log(storedUserData);
        if (storedUserData) {
          setProfileData(storedUserData);
          setEmail(storedUserData.email || "");
          setFirstName(storedUserData.firstName || "");
          setLastName(storedUserData.lastName || "");
          setPhoneNo(storedUserData.phoneNo || "");
          setAddress(storedUserData.address || "");
          setCountry(storedUserData.country || "");
          setPhotoUrl(storedUserData.photoUrl || "");
          setStripeAccountId(storedUserData.stripeAccountId || "");
          checkStripeStatus(retrievedIdToken, storedUserData.stripeAccountId);
        }
      } else {
        console.log("ID token not found in local storage.");
      }
    }
  }

  const updateProfileBasicInfo = async () => {
    try {
      setFormLoading(true);
      const updatedUserBasicInfo = {
        firstName,
        lastName,
        phoneNo,
        address,
        country,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const response = await axios.put(
        `${PROD_URL}/basicInfo`,
        updatedUserBasicInfo,
        config
      );
      if (response.status === 200) {
        const updatedUserData = response.data;
        localStorage.setItem(
          "artistCubeUserData",
          JSON.stringify(updatedUserData)
        );
        toast.success("Basic info updated");
      }
    } catch (error) {
      console.error("Error updating basic info:", error);
      toast.error("Error updating basic info");
    } finally {
      setFormLoading(false);
    }
  };

  const updatePassword = async (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => {
    try {
      setFormLoading(true);
      if (!oldPassword || !newPassword || !confirmPassword) {
        throw new Error("Please fill in all password fields.");
      }
      if (newPassword !== confirmPassword) {
        throw new Error("New password and confirm password must match.");
      }
      if (oldPassword === newPassword) {
        throw new Error(
          "New password should not be the same as the old password."
        );
      }
      const updatedPasswordBody = {
        oldPassword,
        newPassword,
        confirmPassword,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const response = await axios.post(
        `${PROD_URL}/updatepassword`,
        updatedPasswordBody,
        config
      );
      if (response.status === 200) {
        toast.success("Password reset");
      }
    } catch (error: any) {
      console.error("Error updating password:", error);
      toast.error(error.message);
    } finally {
      setFormLoading(false);
    }
  };

  const handleProfilePicChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setFormLoading(true);
      const idToken = await authContext?.user?.getIdToken();
      const fileInput = event.target;
      if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
        return;
      }
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        `${PROD_URL}/member/upload-profile-pic`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Photo uploaded successfully");
        setPhotoUrl(response.data.photoUrl);
        const userData =
          JSON.parse(localStorage.getItem("artistCubeUserData") || "null") ||
          null;
        if (userData) {
          userData.photoUrl = response.data.photoUrl; // Assuming response.data.photoUrl contains the new photo URL
        }
        localStorage.setItem("userData", JSON.stringify(userData));
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      toast.error("Error uploading profile picture");
    } finally {
      setFormLoading(false);
    }
  };
  const getUrlParams = () => {
    // Get the portion of the URL after the hash
    const hashParams = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(hashParams);
    return {
      code: urlParams.get("code"),
    };
  };

  const clearUrlParams = () => {
    // Clear query parameters from the URL
    const baseUrl = window.location.href.split("?")[0];
    window.history.replaceState({}, document.title, baseUrl);
  };

  const completeStripeFlow = async () => {
    // window.location.href =
    //   "https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_Pk3b11BPo9XFev9Ac0XKolHeTEmAFyMD&scope=read_write";
    const stripeProvider = new StripeProvider();
    try {
      var link = await stripeProvider.getAuthorizeUrl();
      if (link) {
        window.location.href = link;
      }
    } catch (error) {
      console.error("Error getting authorize url", error);
    }
  };

  const connectStripeAccount = async (apiToken: string, code: string) => {
    const stripeProvider = new StripeProvider();
    try {
      const user = await stripeProvider.stripeConnectWithUser(apiToken, code);
      if (user) {
        localStorage.setItem("artistCubeUserData", JSON.stringify(user));
        toast.success("Successfully connected your stripe account");
        setStripeButtonEnable(true);
      }
    } catch (error) {
      console.error("Error checking Stripe account status:", error);
    }
  };

  const deauthorizeAccount = async () => {
    setFormLoading(true);
    const stripeProvider = new StripeProvider();
    try {
      const updatedUser = await stripeProvider.deAuthorizeAccount(
        idToken,
        stripeAccountId
      );
      if (updatedUser) {
        localStorage.setItem("userData", JSON.stringify(updatedUser));
        toast.success("Account unlinked from stripe");
        setStripeButtonEnable(false);
      }
    } catch (error) {
      console.error("Error unlinking account", error);
    }
    setFormLoading(false);
  };

  const checkStripeStatus = async (
    apiToken: string,
    stripeAccountId: string
  ) => {
    const stripeProvider = new StripeProvider();
    try {
      const status = await stripeProvider.checkStripeAccountStatus(
        apiToken,
        stripeAccountId
      );
      if (status) {
        setStripeButtonEnable(status);
      }
    } catch (error) {
      console.error("Error checking Stripe account status:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="profile-settings-page">
        <div className="page-title mb-4">Profile Settings</div>

        <div className="profile-settings-div mt-4">
          <div>
            <div style={{ position: "relative", display: "inline-block" }}>
              <img src={photoUrl} className="profile-settings-img me-3" />
              <label
                htmlFor="profilePicUpload"
                className="edit-photo-btn clickable"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M10.545 6.75L11.25 7.455L4.44 14.25H3.75V13.56L10.545 6.75ZM13.245 2.25C13.0575 2.25 12.8625 2.325 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28C15.825 4.9875 15.825 4.5 15.5325 4.2225L13.7775 2.4675C13.6275 2.3175 13.44 2.25 13.245 2.25ZM10.545 4.6425L2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425Z"
                    fill="#606060"
                  />
                </svg>
              </label>
              <input
                type="file"
                id="profilePicUpload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleProfilePicChange} // handleProfilePicChange is a function to handle the change event when a file is selected
              />
            </div>
            <div className="me-2" style={{ display: "inline-block" }}>
              {profileData?.userName}
            </div>
            {profileData?.isVerified ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.6 22.5L6.7 19.3L3.1 18.5L3.45 14.8L1 12L3.45 9.2L3.1 5.5L6.7 4.7L8.6 1.5L12 2.95L15.4 1.5L17.3 4.7L20.9 5.5L20.55 9.2L23 12L20.55 14.8L20.9 18.5L17.3 19.3L15.4 22.5L12 21.05L8.6 22.5ZM10.95 15.55L16.6 9.9L15.2 8.45L10.95 12.7L8.8 10.6L7.4 12L10.95 15.55Z"
                  fill="var(--primary-color)"
                />
              </svg>
            ) : null}
          </div>
          {!stripeButtonEnable ? (
            <div
              className="profile-card-edit-btn"
              onClick={completeStripeFlow}
              // style={{ width: "250px" }}
            >
              <ModeEditOutlinedIcon />
              <span className="ms-2">Complete Stripe Flow</span>
            </div>
          ) : (
            <div className="">
              <a
                className="profile-card-edit-btn mb-2"
                href="https://dashboard.stripe.com/"
                // style={{ width: "250px" }}
              >
                <span className="">Go to stripe dashboard</span>
              </a>
              <div
                className="profile-card-edit-btn"
                onClick={deauthorizeAccount}
                // style={{ width: "250px" }}
              >
                <LockOpen />
                <span className="ms-2">
                  {formLoading ? "Unlinking...." : "Unlink Stripe Account"}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="profile-settings-card mt-5">
          <div className="profile-card-header">
            <div className="profile-card-name">
              <span className="">Basic Information</span>
            </div>
            <div
              className="profile-card-edit-btn"
              onClick={() => setBasicInfoDisabled(!basicInfoDisabled)}
            >
              <ModeEditOutlinedIcon />
              <span className="ms-2">Edit</span>
            </div>
          </div>

          <div className="profile-text-fields">
            <div className="row gy-4">
              <div className="col-md-4">
                <label className="form-label profile-label">First Name</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-control profile-input"
                  disabled={basicInfoDisabled}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">Last Name</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-control profile-input"
                  disabled={basicInfoDisabled}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">Email</label>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control profile-input"
                  disabled={basicInfoDisabled}
                  placeholder="Email"
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">Address</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="form-control profile-input"
                  disabled={basicInfoDisabled}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">Phone Number</label>
                <input
                  type="number"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  className="form-control profile-input"
                  disabled={basicInfoDisabled}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">Country</label>
                <input
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="form-control profile-input"
                  disabled={basicInfoDisabled}
                />
              </div>
            </div>
            {!basicInfoDisabled && (
              <div className="save-btn-position mt-3">
                <div
                  className="profile-card-edit-btn"
                  onClick={() => updateProfileBasicInfo()}
                >
                  <span>{formLoading ? "Updating" : "Save"}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="profile-settings-card mt-4">
          <div className="profile-card-header">
            <div className="profile-card-name">
              <span className="">Password</span>
            </div>
            <div
              className="profile-card-edit-btn"
              onClick={() => setPasswordDisabled(!passwordDisabled)}
            >
              <ModeEditOutlinedIcon />
              <span className="ms-2">Edit</span>
            </div>
          </div>

          <div className="profile-text-fields">
            <div className="row gy-4">
              <div className="col-md-4">
                <label className="form-label profile-label">Old Password</label>
                <input
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="form-control profile-input"
                  disabled={passwordDisabled}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="form-control profile-input"
                  disabled={passwordDisabled}
                />
              </div>
              <div className="col-md-4">
                <label className="form-label profile-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control profile-input"
                  disabled={passwordDisabled}
                />
              </div>
            </div>
            {!passwordDisabled && (
              <div className="save-btn-position mt-3">
                <div
                  className="profile-card-edit-btn"
                  onClick={() =>
                    updatePassword(oldPassword, newPassword, confirmPassword)
                  }
                >
                  <span>{formLoading ? "Updating" : "Save"}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="profile-settings-card mt-4">
          <div className="profile-card-header">
            <div className="profile-card-name">
              <span className="">Invoices and Billing</span>
            </div>
            <div className="profile-card-edit-btn">
              <ModeEditOutlinedIcon />
              <span className="ms-2">Edit</span>
            </div>
          </div>

          <div className="profile-billing-section">
            <div className="profile-table-container">
              <table>
                <thead>
                  <tr>
                    <th>SERVICE IMAGE</th>
                    <th>SERVICE NAME</th>
                    <th>EVENT DATE</th>
                    <th>INVOICE DATE</th>
                    <th>PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2].map((item) => (
                    <tr>
                      <td>
                        <img src={serviceImg} className="profile-service-img" />
                      </td>
                      <td>Service Name</td>
                      <td>08-05-24</td>
                      <td>08-05-24</td>
                      <td>$XXX.XXX</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
