import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import "./style.css";
import { IoIosShare } from "react-icons/io";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import productImg from "../../assests/images/other-products-img.png";
import videoImg from "../../assests/images/video_img_full.png";
import img1 from "../../assests/images/img1_full.png";
import img2 from "../../assests/images/img2_full.jpeg";
import img3 from "../../assests/images/img3_full.jpeg";
import { FaPlus } from "react-icons/fa6";
import ReactOwlCarousel from "react-owl-carousel";
import { contentOptions } from "./data";
import { LuSendHorizonal } from "react-icons/lu";
import { CgAttachment } from "react-icons/cg";
import { isEmpty } from "lodash";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { ImEnlarge2 } from "react-icons/im";
import { AiOutlineExpand } from "react-icons/ai";
import ChatBox from "../../components/chatbox"; // Make sure the path is correct
import Footer from "../../components/footer";
import {
  convertToKebabCase,
  getCreateEventAmount,
  handleCopyClick,
  titleCase,
} from "../../helpers/utils";
import { PROD_URL, supportedVideoExtensions } from "../../helpers/constants";
import axios from "axios";
import { toast } from "react-toastify";
import ShareModal from "../../components/share-modal";
import { ControlBar, Player } from "video-react";

export default function SupplierInfo() {
  const location = useLocation();
  const { supplier_id } = useParams();
  // let { data, searchedData } = location.state;
  // Check if location.state is not null and has the 'data' property
  console.log("location:", location);

  const { data, selectedEvent = {}, page } = location.state || {};

  const [serviceData, setServiceData] = useState(data);
  const [unitName, setUnitName] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const [otherProducts, setOtherProducts] = useState<any[]>([]);
  const [otherProductsLoading, setOtherProductsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("serviceData:", serviceData);
    if (serviceData) {
      const { unitName, total_amount, unit_price } = getCreateEventAmount(
        serviceData.priceTypeSelected,
        serviceData
      );
      setUnitName(unitName);
      setUnitPrice(unit_price);
      setTotalAmount(total_amount);
      getOtherProductsData(serviceData.serviceId, serviceData.supplierUid);
    } else {
      getServiceData();
    }
  }, []);

  async function getServiceData() {
    try {
      const response: any = await axios.get(
        `${PROD_URL}/service/${supplier_id}/full-details`
      );
      console.log("response from service data", response);
      const { unitName, total_amount, unit_price } = getCreateEventAmount(
        response.data.priceTypeSelected,
        response.data
      );
      setServiceData(response.data);
      setUnitName(unitName);
      setUnitPrice(unit_price);
      setTotalAmount(total_amount);
      await getOtherProductsData(
        response.data.serviceId,
        response.data.supplierUid
      );
    } catch (error) {
      console.log(error);
    }
  }

  const getOtherProductsData = async (
    serviceId: string,
    supplierUid: string
  ) => {
    try {
      setOtherProductsLoading(true);
      const response: any = await axios.post(`${PROD_URL}/other-services`, {
        supplierUid: supplierUid,
        serviceId: [serviceId],
      });
      console.log("response from other-products data", response);
      setOtherProducts(
        response.data.otherServices
          .filter((product: any) => product.serviceId != serviceId)
          .slice(0, 2)
      );
    } catch (error) {
      console.log(error);
    } finally {
      setOtherProductsLoading(false);
    }
  };

  const addToEvent = () => {
    sessionStorage.setItem("dataFromSupplierInfo", JSON.stringify(serviceData));
    // onGoBack(serviceData);
    navigate(-1);
  };

  return (
    <div>
      <Navbar />
      {serviceData ? (
        <div className="supplier-info">
          <div className="supplier-header d-flex align-items-center">
            <div
              className="supplier-name"
              onClick={() => {
                if (data) navigate(-1);
              }}
            >
              {data && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 12 11"
                  fill="none"
                >
                  <path
                    d="M5.25 10.25L0.75 5.75M0.75 5.75L5.25 1.25M0.75 5.75L11.25 5.75"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              <span>{serviceData.name}</span>
            </div>
            <div className="share-btn" onClick={() => setShowShareModal(true)}>
              <IoIosShare size={20} />
              <span>Share</span>
            </div>
          </div>

          <div className="supplier-body">
            <div className="row g-4">
              <div className="col-lg-8">
                <div className="height-adjustment">
                  <div className="description-box">
                    <div className="box-header">Content</div>
                    <div className="box-desc">
                      <PhotoProvider>
                        {supportedVideoExtensions.some((ext) =>
                          serviceData.photoAndVideoUrls?.[0]
                            ?.toLowerCase?.()
                            .includes(ext)
                        ) ? (
                          <div className="mb-4">
                            <Player
                              src={serviceData.photoAndVideoUrls?.[0] ?? ""}
                              height={400}
                              fluid={false}
                              //@ts-ignore
                              width="100%"
                            >
                              <ControlBar autoHide={false} />
                            </Player>
                          </div>
                        ) : (
                          <PhotoView
                            src={serviceData.photoAndVideoUrls?.[0] ?? ""}
                          >
                            <div
                              className="video mb-4 clickable"
                              style={{
                                backgroundImage: `url(${
                                  serviceData.photoAndVideoUrls?.[0] ?? ""
                                })`,
                              }}
                            >
                              <div className="enlarger">
                                <AiOutlineExpand color="white" size={25} />
                              </div>
                            </div>
                          </PhotoView>
                        )}

                        {serviceData.photoAndVideoUrls?.[1] && (
                          <ReactOwlCarousel
                            className="owl-theme"
                            {...contentOptions}
                          >
                            {serviceData.photoAndVideoUrls.map(
                              (item: any, index: number) =>
                                index !== 0 &&
                                (supportedVideoExtensions.some((ext) =>
                                  item?.toLowerCase?.().includes(ext)
                                ) ? (
                                  <Player
                                    src={item}
                                    height={250}
                                    fluid={false}
                                    //@ts-ignore
                                    width="100%"
                                  >
                                    <ControlBar autoHide={false} />
                                  </Player>
                                ) : (
                                  <PhotoView src={item}>
                                    <div
                                      className="img1 clickable"
                                      style={{
                                        backgroundImage: `url(${item})`,
                                      }}
                                    >
                                      <div className="enlarger">
                                        <AiOutlineExpand
                                          color="white"
                                          size={25}
                                        />
                                      </div>
                                    </div>
                                  </PhotoView>
                                ))
                            )}
                          </ReactOwlCarousel>
                        )}
                      </PhotoProvider>
                    </div>
                  </div>

                  <div className="description-box mt-4">
                    <div className="box-header">About Our Services</div>
                    <div className="box-desc">
                      {serviceData.description && (
                        <div className="mb-2">
                          {serviceData.description ?? ""}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="description-box mt-4">
                    <div className="box-header other-products-header">
                      <div>Other Products</div>
                    </div>
                    <div className="box-desc">
                      <div className="row g-4">
                        {otherProductsLoading ? (
                          <div>Loading data...</div>
                        ) : otherProducts.length > 0 ? (
                          otherProducts.map((product) => (
                            <div className="col-md-6">
                              <div
                                className="product-info clickable"
                                onClick={() => {
                                  navigate(
                                    `/artist/${convertToKebabCase(
                                      product.category
                                    )}/${convertToKebabCase(product.name)}/${
                                      product.city
                                    }/${product.serviceId}`,
                                    {
                                      state: {
                                        data: product,
                                        page,
                                      },
                                    }
                                  );
                                }}
                              >
                                {supportedVideoExtensions.some((ext) =>
                                  product.photoAndVideoUrls?.[0]
                                    ?.toLowerCase()
                                    .includes(ext)
                                ) ? (
                                  <Player
                                    src={product.photoAndVideoUrls[0]}
                                    height={110}
                                    fluid={false}
                                    width={110}
                                  >
                                    <ControlBar autoHide={false} />
                                  </Player>
                                ) : (
                                  <img
                                    src={product.photoAndVideoUrls?.[0] ?? ""}
                                    className="other-product-img"
                                  />
                                )}

                                <div>
                                  <div className="product-txt">
                                    {product.name}
                                  </div>
                                  <div className="product-sub-txt">
                                    {product.category}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>This artist does not offer other products.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="height-adjustment">
                  <div className="info-cards">
                    <div className="d-flex card-pricing">
                      <div className="left-headings">Price</div>
                      <div style={{ textAlign: "right" }}>
                        <div className="per-person-price">
                          {unitName === "on request" ? "-" : `€${unitPrice}`}
                        </div>
                        <div className="per-person">
                          {unitName === "on request"
                            ? titleCase(unitName)
                            : unitName}
                        </div>
                      </div>
                    </div>
                    <div className="border1"></div>
                    <div
                      className="d-flex card-pricing"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="left-headings">{"Total"}</div>
                      <div>
                        {unitName === "on request" ? "-" : `€${totalAmount}`}
                      </div>
                    </div>
                    <div className="d-flex card-pricing">
                      <div className="left-headings">Tax</div>
                      <div>{serviceData.taxPercent ?? ""}%</div>
                    </div>

                    <div className="border1"></div>

                    <div className="d-flex card-pricing">
                      <div className="left-headings">Total Payable</div>
                      <div className="total-price">
                        {unitName === "on request"
                          ? "-"
                          : `€${(
                              totalAmount +
                              totalAmount * (serviceData.taxPercent / 100)
                            ).toFixed(2)}`}
                      </div>
                    </div>
                    {page == "create-event" && (
                      <div className="add-to-event-btn" onClick={addToEvent}>
                        + Add to Event
                      </div>
                    )}
                    {/* <div className="request-offer-btn">Request Offer</div> */}
                  </div>

                  <div className="info-cards mt-4">
                    {serviceData.city && (
                      <div className="service-event-info-flex">
                        <div style={{ position: "relative", top: "-2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_0_627)">
                              <path
                                d="M10 1.66699C11.9891 1.66699 13.8968 2.45717 15.3033 3.86369C16.7098 5.27021 17.5 7.17787 17.5 9.16699C17.5 11.7287 16.1033 13.8253 14.6317 15.3295C13.8963 16.0727 13.094 16.7467 12.235 17.3428L11.88 17.5845L11.7133 17.6953L11.3992 17.8953L11.1192 18.0662L10.7725 18.2678C10.5371 18.4018 10.2709 18.4723 10 18.4723C9.72913 18.4723 9.46291 18.4018 9.2275 18.2678L8.88083 18.0662L8.4475 17.7995L8.2875 17.6953L7.94583 17.4678C7.01915 16.8406 6.15589 16.1244 5.36833 15.3295C3.89667 13.8245 2.5 11.7287 2.5 9.16699C2.5 7.17787 3.29018 5.27021 4.6967 3.86369C6.10322 2.45717 8.01088 1.66699 10 1.66699ZM10 6.66699C9.6717 6.66699 9.34661 6.73166 9.04329 6.85729C8.73998 6.98293 8.46438 7.16708 8.23223 7.39923C8.00009 7.63137 7.81594 7.90697 7.6903 8.21028C7.56466 8.5136 7.5 8.83869 7.5 9.16699C7.5 9.4953 7.56466 9.82039 7.6903 10.1237C7.81594 10.427 8.00009 10.7026 8.23223 10.9348C8.46438 11.1669 8.73998 11.3511 9.04329 11.4767C9.34661 11.6023 9.6717 11.667 10 11.667C10.663 11.667 11.2989 11.4036 11.7678 10.9348C12.2366 10.4659 12.5 9.83003 12.5 9.16699C12.5 8.50395 12.2366 7.86807 11.7678 7.39923C11.2989 6.93038 10.663 6.66699 10 6.66699Z"
                                fill="var(--primary-color)"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_0_627">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="service-info-card-txt1">
                          {serviceData.city}
                        </div>
                      </div>
                    )}

                    {!isEmpty(serviceData.subCategory) && (
                      <div className="service-event-info-flex">
                        <div style={{ position: "relative", top: "-2px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M8 5C8 4.46957 8.21071 3.96086 8.58579 3.58579C8.96086 3.21071 9.46957 3 10 3C10.5304 3 11.0391 3.21071 11.4142 3.58579C11.7893 3.96086 12 4.46957 12 5V5.79C14.892 6.65 17 9.329 17 12.5C17 12.6326 16.9473 12.7598 16.8536 12.8536C16.7598 12.9473 16.6326 13 16.5 13H3.5C3.36739 13 3.24021 12.9473 3.14645 12.8536C3.05268 12.7598 3 12.6326 3 12.5C3.00009 10.9924 3.48674 9.52499 4.38757 8.31607C5.28841 7.10714 6.55534 6.22122 8 5.79V5ZM10.5 8.5C10.922 8.5 11.34 8.504 11.768 8.658C12.181 8.806 12.638 9.109 13.084 9.778C13.1204 9.83263 13.1673 9.87955 13.2219 9.91607C13.2764 9.9526 13.3377 9.97802 13.4021 9.99088C13.4665 10.0037 13.5328 10.0038 13.5972 9.99103C13.6616 9.97827 13.7229 9.95294 13.7775 9.9165C13.8321 9.88006 13.879 9.83321 13.9156 9.77864C13.9521 9.72406 13.9775 9.66283 13.9904 9.59843C14.0032 9.53404 14.0033 9.46774 13.9905 9.40332C13.9778 9.3389 13.9524 9.27763 13.916 9.223C13.361 8.391 12.736 7.943 12.106 7.717C11.505 7.5 10.93 7.5 10.52 7.5H10.5C10.3674 7.5 10.2402 7.55268 10.1464 7.64645C10.0527 7.74021 10 7.86739 10 8C10 8.13261 10.0527 8.25979 10.1464 8.35355C10.2402 8.44732 10.3674 8.5 10.5 8.5ZM3.5 14C3.10218 14 2.72064 14.158 2.43934 14.4393C2.15804 14.7206 2 15.1022 2 15.5C2 15.8978 2.15804 16.2794 2.43934 16.5607C2.72064 16.842 3.10218 17 3.5 17H16.5C16.8978 17 17.2794 16.842 17.5607 16.5607C17.842 16.2794 18 15.8978 18 15.5C18 15.1022 17.842 14.7206 17.5607 14.4393C17.2794 14.158 16.8978 14 16.5 14H3.5Z"
                              fill="var(--primary-color)"
                            />
                          </svg>
                        </div>
                        <div className="service-info-card-txt2">
                          {serviceData.subCategory?.join(", ")}
                        </div>
                      </div>
                    )}

                    {serviceData.feature && (
                      <div className="service-info-tags-div">
                        {serviceData.feature.map((item: any) => (
                          <div className="service-info-tags">{item}</div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <ChatBox
                      serviceUid={serviceData.serviceId}
                      supplierUid={serviceData.supplierUid}
                      supplierName={serviceData.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ShareModal
            url={window.location.href}
            title={serviceData.name}
            description={serviceData.description}
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
          />
        </div>
      ) : (
        <div className="loading-content">
          <div className="text-center">
            {[1, 2, 3].map((item) => (
              <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ))}
            <div>Loading...</div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
