import React, { useState, useEffect } from "react";
import { CgAttachment } from "react-icons/cg";
import { LuSendHorizonal } from "react-icons/lu";
import { useChatbot } from "../../context/ChatbotContext";
import "./ChatBox.css"; // Assuming your CSS is here

interface chatbotProps {
  serviceUid: string;
  supplierUid: string;
  supplierName?: string;
}

const CursorSVG = () => (
  <svg viewBox="8 4 8 16" xmlns="http://www.w3.org/2000/svg" className="cursor">
    <rect x="10" y="6" width="4" height="12" fill="#fff" />
  </svg>
);

const ChatBox: React.FC<chatbotProps> = ({
  serviceUid,
  supplierUid,
  supplierName,
}) => {
  const { messages, sendMessage, lastServiceUid } = useChatbot();
  const [newMessage, setNewMessage] = useState("");
  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(true);

  useEffect(() => {
    if (serviceUid != lastServiceUid) messages.length = 1;
  }, []);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.sender === "bot") {
      setCompletedTyping(false);
      let i = 0;
      const stringResponse = lastMessage.text;
      const intervalId = setInterval(() => {
        setDisplayResponse(stringResponse.slice(0, i));
        i++;
        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
        }
      }, 10); // Adjust speed as needed

      return () => clearInterval(intervalId);
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendMessage(newMessage, supplierUid, serviceUid);
      setNewMessage("");
    }
  };

  return (
    <div className="supplier-chat-box bot" style={{ borderRadius: "20px" }}>
      <div className="chat-header">
        <div className="chat-header-flex">
          <div className="card-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g clip-path="url(#clip0_0_2081)">
                <path
                  d="M8.71113 0.489258C6.64318 0.488706 4.65968 1.3096 3.19693 2.77138C1.73418 4.23316 0.91196 6.2161 0.911133 8.28406C0.911406 9.3081 1.11338 10.3221 1.50551 11.268C1.89764 12.214 2.47226 13.0735 3.19656 13.7974C3.92086 14.5213 4.78065 15.0955 5.72684 15.4871C6.67303 15.8787 7.6871 16.0802 8.71113 16.0799C10.0694 16.0799 11.7375 15.7388 12.9273 15.095L15.8289 16.0539C15.9205 16.084 16.0187 16.088 16.1125 16.0655C16.2063 16.0429 16.2919 15.9948 16.3599 15.9264C16.4279 15.858 16.4755 15.772 16.4974 15.6781C16.5194 15.5841 16.5148 15.486 16.4841 15.3945L15.5211 12.5127C16.2199 11.2928 16.5111 9.69846 16.5111 8.28406C16.5103 6.2161 15.6881 4.23316 14.2253 2.77138C12.7626 1.3096 10.7791 0.488706 8.71113 0.489258Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_0_2081">
                  <rect
                    width="15.6"
                    height="15.6"
                    fill="white"
                    transform="translate(0.911133 0.489258)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <div className="chat-main-heading">
              {supplierName ? `${supplierName} Bot` : "Chat Bot"}
            </div>
            <div className="chat-sub-heading">
              Ask me {supplierName ? `about ${supplierName}` : "anything..."}
            </div>
          </div>
        </div>
      </div>

      <div className="chat-body">
        {messages.map((message, index) => (
          <div
            key={message.id}
            className={
              message.sender === "user" ? "user-msg-flex" : "supplier-msg-flex"
            }
          >
            <div
              className={
                message.sender === "user"
                  ? "user-msg-time"
                  : "supplier-msg-time"
              }
            >
              {message.sender === "bot" && (
                <div className="supplier-msg-name">Chat Bot</div>
              )}
              <div
                className={
                  message.sender === "user"
                    ? "user-message"
                    : "supplier-message"
                }
              >
                {/* Check if it's the last message from the bot and typing animation is not completed */}
                {message.sender === "bot" &&
                index === messages.length - 1 &&
                !completedTyping ? (
                  <>
                    {displayResponse}
                    <CursorSVG />
                  </>
                ) : (
                  message.text
                )}
              </div>
              <div
                className={
                  message.sender === "user"
                    ? "user-message-time"
                    : "supplier-message-time"
                }
              >
                {message.time}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-footer">
        <div className="chat-message-flex">
          <div className="chat-message-box form-control d-flex">
            <input
              type="text"
              placeholder="Type something here"
              className="chat-message-input"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            />
            {/* <label htmlFor="attachment">
              <CgAttachment
                size={20}
                color="gray"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input type="file" id="attachment" style={{ display: "none" }} /> */}
          </div>
          <button className="message-send-btn" onClick={handleSendMessage}>
            <LuSendHorizonal size={23} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
