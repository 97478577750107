export const contentOptions = {
    items: 4,
    loop: false,
    margin: 25,
    responsive: {
      0:{
        items:1
      },
      768: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
  };