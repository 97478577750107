import musicIcon from "../../assests/images/service-category-icons/music-note-svgrepo-com.svg";
import graffitiIcon from "../../assests/images/category-icons/graffiti.svg";
import tattooIcon from "../../assests/images/category-icons/tattoo-machine-svgrepo-com.svg";
import installIcon from "../../assests/images/category-icons/installation.svg";
import paintIcon from "../../assests/images/category-icons/paint.svg";
import musikIcon from "../../assests/images/category-icons/music-notes-svgrepo-com.svg";

export const serviceMenu = [
  "Graffiti",
  "Tattoo",
  "Musik",
  "Installation",
  "Painting",
];

export const servicesWithIcon = {
  Graffiti: graffitiIcon,
  Tattoo: tattooIcon,
  Musik: musikIcon,
  Installation: installIcon,
  Painting: paintIcon,
};
