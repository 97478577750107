// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { PROD_URL } from "../helpers/constants";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = PROD_URL.includes("development")
  ? //dev
    {
      apiKey: "AIzaSyCRYaQ9-k-d4RwAF-jx07kbSp3wjmYuhBk",
      authDomain: "artistcube-dev.firebaseapp.com",
      projectId: "artistcube-dev",
      storageBucket: "artistcube-dev.appspot.com",
      messagingSenderId: "353479895368",
      appId: "1:353479895368:web:aeb0824715dde3ca9eab7d",
      databaseURL: "https://artistcube-dev.firebaseio.com",
      measurementId: "G-8LTS7FL83V",
    }
  : //prod
    {
      apiKey: "AIzaSyBAN7Tq7oMxTCwAmhjFZ8sAXYVw6TzD2TE",
      authDomain: "artistcube-729b6.firebaseapp.com",
      projectId: "artistcube-729b6",
      storageBucket: "artistcube-729b6.appspot.com",
      messagingSenderId: "144004752044",
      appId: "1:144004752044:web:360912b9bfe5becb933509",
      measurementId: "G-L1SEN9QET7",
    };

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "http://artist-cube.de/#/login",
  // url: "http://localhost:3000/login",
  // This must be true.
  handleCodeInApp: true,
  // dynamicLinkDomain: "",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
//const messaging = getMessaging(app);

export { auth, db, storage, provider, actionCodeSettings };
