import React from "react";
import {
  AGB_POLICY_LINK,
  COOKIES_POLICY_LINK,
  PRIVACY_POLICY_LINK,
} from "../../helpers/constants";
import "./style.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        {/* <div className="footer-subdiv"> */}
          <div>
            <a href={PRIVACY_POLICY_LINK} target="_blank">
              Datenschutzrichtlinie
            </a>
          </div>
          <div>
            <a href={COOKIES_POLICY_LINK} target="_blank">
              Cookie-Richtlinie
            </a>
          </div>
        {/* </div> */}

        {/* <div className="footer-subdiv"> */}
          <div>
            <a href={AGB_POLICY_LINK} target="_blank">
              Geschäftsbedingungen
            </a>
          </div>
          <div>
            <Link to="/impressum">Impressum</Link>
          </div>
        {/* </div> */}
      </div>
      <div>© 2024 GalaCube | All Rights Reserved</div>
    </div>
  );
}
