import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { PROD_URL } from "../helpers/constants";
import { SupplierService } from "../components/add-new-service-modal/types";
import ServiceProvider from "../providers/serviceProvider";
import { useAuth } from "./AuthContext";

interface ServicesContextType {
  services: SupplierService[];
  fetchServices: (idToken: string) => void;
  addService: (newService: SupplierService) => void;
  updateService: (updatedService: SupplierService) => void;
  deleteService: (serviceIdToDelete: string) => void;
}

// Create the context
const ServicesContext = createContext<ServicesContextType | undefined>(
  undefined
);

// Custom hook to access the services context
export const useServices = () => {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error("useServices must be used within a ServicesProvider");
  }
  return context;
};

// Provider component to wrap your application and provide the services data
export const ServicesProvider = ({ children }: any) => {
  const [services, setServices] = useState<SupplierService[]>([]);
  const { user } = useAuth();

  // Fetch services from the API
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const retrievedIdToken = await user?.getIdToken();
    if (retrievedIdToken) {
      fetchServices(retrievedIdToken);
    } else {
      console.log("unable to reterive token");
    }
  };

  const fetchServices = async (idToken: string) => {
    try {
      const serviceProvider = new ServiceProvider();
      const services = await serviceProvider.getMyServices(idToken); // Pass idToken here
      if (services !== null) {
        console.log(services);
        setServices(services);
      } else {
        console.error("Error fetching services");
      }
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };
  // Function to add a new service to the context
  const addService = (newService: SupplierService) => {
    setServices((prevServices) => [...prevServices, newService]);
  };

  const deleteService = (serviceIdToDelete: string) => {
    setServices((prevServices) =>
      prevServices.filter((service) => service.serviceId !== serviceIdToDelete)
    );
  };

  const updateService = (updatedService: SupplierService) => {
    setServices((prevServices) =>
      prevServices.map((service) => {
        if (service.serviceId === updatedService.serviceId) {
          return updatedService;
        }
        return service;
      })
    );
  };

  return (
    <ServicesContext.Provider
      value={{
        services,
        fetchServices,
        addService,
        updateService,
        deleteService,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
