import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import "./style.css";
import loginImg from "../../assests/images/loginImg.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import {
  UserMetadata,
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "@firebase/auth";
import { auth, provider } from "../../utils/firebase.utils";
import { setDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { PROD_URL } from "../../helpers/constants";
import axios from "axios";
import Footer from "../../components/footer";
import { UserData } from "./types";
import { AuthContext } from "../../context/AuthContext";

interface CustomUserMetadata extends UserMetadata {
  createdAt?: number;
  lastLoginAt?: number;
  creationTime?: string;
  lastSignInTime?: string;
}

export default function Login() {
  const location = useLocation();
  const { fromPage = "" } = location.state || {};
  const authContext = useContext(AuthContext);
  const [tab1, setTab1] = useState("tab-active");
  const [tab2, setTab2] = useState("tab-inactive");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (authContext?.user) {
  //     navigate(`/${fromPage}`);
  //   }
  // }, [authContext]);

  const signInWithGoogle = async () => {
    try {
      setFormLoading(true);
      const result = await signInWithPopup(auth, provider);
      const newUser = getAdditionalUserInfo(result)?.isNewUser;
      const customMetadata: CustomUserMetadata = result.user.metadata;
      console.log(result, newUser);
      if (!newUser) {
        console.log(result.user);
        const artistCubeIdToken = await result.user.getIdToken();
        updateActiveTimeMember(result.user.uid, artistCubeIdToken);
      } else {
        const { uid, displayName, email, phoneNumber, photoURL } = result.user;
        console.log("user");
        console.log(result.user);
        const user: UserData = {
          uid, // necessary for social account
          userName: displayName || "", // assuming displayName is the username
          email: email || "", // email
          phoneNo: phoneNumber || "", // phoneNo
          photoUrl: photoURL || "", // photoUrl
          role: tab1 === "tab-active" ? "user" : "supplier", // assuming UserRole is an enum with default value
          isVerified: false,
          isBanned: false,
          isDeleted: false,
          isActive: true,
          createDate: new Date(),
          updateDate: new Date(),
          lastActiveDateTime: new Date(),
          stripeAccountId: "",
          stripeCustomerId: "",
        };
        const idToken = await result.user.getIdToken();
        user.lastActiveDateTime = new Date();

        setFormLoading(false);
        signUpSocial(user);
      }
    } catch (err: any) {
      console.error(err);
      setFormLoading(false);
      toast.error(err.message);
    }
  };

  const normalSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!email || !email.trim()) {
        throw new Error("Email is required.");
      }
      if (!password || !password.trim()) {
        throw new Error("Password is required.");
      }
      // Validate if email is in valid format
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        throw new Error("Invalid email format.");
      }
      setFormLoading(true);
      const result = await signInWithEmailAndPassword(auth, email, password);
      const artistCubeIdToken = await result.user.getIdToken();
      updateActiveTimeMember(result.user.uid, artistCubeIdToken);
    } catch (err: any) {
      console.error(err);
      setFormLoading(false);
      toast.error(err.message);
    }
  };

  const updateActiveTimeMember = async (
    memberId: string,
    artistCubeIdToken: string
  ) => {
    try {
      const updatedLastActiveDateTime = {
        lastActiveDateTime: new Date(),
      };
      // Set the Authorization header with the ID token
      const config = {
        headers: {
          Authorization: `Bearer ${artistCubeIdToken}`,
        },
      };
      const response = await axios.put(
        `${PROD_URL}/member/${memberId}`,
        updatedLastActiveDateTime,
        config
      );
      const updatedUserData = response.data;
      console.log("updated user data", updatedUserData);
      if (response.status === 200) {
        console.log(updatedUserData);
        localStorage.setItem(
          "artistCubeUserData",
          JSON.stringify(updatedUserData)
        );
        setFormLoading(false);
        navigate(`/${fromPage}`, { state: updatedUserData });
        // No need to navigate here as it is done after sign in
      }
    } catch (error: any) {
      setFormLoading(false);
      console.error(error);
    }
  };

  const signUpSocial = async (user: UserData) => {
    try {
      const response = await axios.post(`${PROD_URL}/social-account`, user);
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      const signupResponse = response.data;

      toast.success("Account created!");
      localStorage.setItem("artistCubeUserData", JSON.stringify(user));
      setTimeout(() => {
        navigate(`/${fromPage}`, { state: user });
      }, 1000);
    } catch (error: any) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="d-flex">
        <div className="left-col">
          <div className="signup-section">
            <h3 className="mb-4">Login</h3>
            <h5 className="mb-4">I am...</h5>

            <div className="d-flex tabs form-control">
              <div
                className={tab1}
                onClick={() => {
                  setTab1("tab-active");
                  setTab2("tab-inactive");
                }}
              >
                Customer
              </div>
              <div
                className={tab2}
                onClick={() => {
                  setTab1("tab-inactive");
                  setTab2("tab-active");
                }}
              >
                Supplier
              </div>
            </div>

            <div className="text-center">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control textbox"
                  id="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div
                className="d-flex align-items-center"
                style={{ position: "relative" }}
              >
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control textbox"
                  id="password"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
                {passwordVisible ? (
                  <VisibilityOffOutlinedIcon
                    className="eyeIcon clickable"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    className="eyeIcon clickable"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                )}
              </div>

              <div style={{ marginTop: "35px" }}>
                <button
                  onClick={normalSignIn}
                  className="form-control signup-btn"
                  disabled={formLoading}
                >
                  {formLoading ? "Logging in ..." : "Login"}
                </button>
              </div>

              <div className="mt-3 mb-2">or</div>
              <div className="mb-4">Continue with your account</div>

              <div className="d-flex btn-div">
                <div
                  className="form-control google-btn"
                  onClick={signInWithGoogle}
                >
                  <i className="fab fa-google me-2"></i>Google
                </div>
              </div>

              <div className="mt-4">
                Don't have an account?{" "}
                <Link className="login-link" to="/signup">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="right-col login-img-div">
          <img src={loginImg} width="100%" />
        </div>
      </div>
    </>
  );
}
