import React, { useState } from "react";
import { IoLockClosedSharp } from "react-icons/io5";
import "./style.css";
import { COOKIES_POLICY_LINK } from "../../helpers/constants";
import { Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";

export default function CookieBanner() {
  const [, setCookie] = useCookies(["cookieConsent"]);

  const handleAccept = () => {
    setCookie("cookieConsent", "accepted", { path: "/" });
  };

  const handleReject = () => {
    setCookie("cookieConsent", "declined", { path: "/" });
  };

  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      dialogClassName="cookie-banner"
    >
      <Modal.Body>
        <div className="banner-div">
          <div className="banner-heading me-3">Schutz deiner Daten</div>
          <div>
            <IoLockClosedSharp size={18} />
          </div>
        </div>

        <div className="banner-text">
          Wir verwenden Cookies und ähnliche Technologien, um Inhalte zu
          personalisieren, das Nutzererlebnis zu optimieren sowie um Werbung
          anzupassen und zu bewerten. Indem du auf Okay klickst oder in den
          Cookie-Einstellungen eine Option aktivierst, stimmst du dem zu. Dies
          wird auch in unserer{" "}
          <a href={COOKIES_POLICY_LINK} target="_blank">
            Cookie-Richtlinie
          </a>{" "}
          beschrieben. Um deine Einstellungen zu ändern oder deine Einwilligung
          zu widerrufen, aktualisiere einfach deine Cookie-Einstellungen.
        </div>

        <div className="banner-btn-div my-3">
          <button className="banner-btn1" onClick={handleAccept}>
            Accept
          </button>
          <button className="banner-btn2" onClick={handleReject}>
            Decline
          </button>
        </div>

        {/* <div className="banner-later-btn" onClick={handleBanner}>
        Nicht annehmen und weiter
      </div> */}
      </Modal.Body>
    </Modal>
  );
}
