import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ShareModalProps } from "./types";
import { convertToSnakeCase, handleCopyClick } from "../../helpers/utils";
import "./style.css";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ShareModal({
  url,
  title,
  description,
  showShareModal,
  setShowShareModal,
}: ShareModalProps) {
  const [valueCopied, setValueCopied] = useState(false);

  return (
    <Modal
      show={showShareModal}
      onHide={() => setShowShareModal(false)}
      id="share-modal"
      centered
      dialogClassName=""
    >
      <Modal.Header closeButton>
        <Modal.Title>Share this artist</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" value={url} readOnly />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  handleCopyClick(url);
                  setValueCopied(true);
                }}
                disabled={valueCopied}
              >
                {valueCopied ? "Copied!" : "Copy"}
              </button>
            </div>
          </div>

          <div className="share-modal-div">
            <div className="share-modal-text">or share with</div>

            <div className="share-icons-div mt-3">
              <WhatsappShareButton
                url={url}
                title={`Artist: ${title}`}
                separator={"\n"}
              >
                <WhatsappIcon round size={45} />
              </WhatsappShareButton>

              <span className="ms-2" />

              <FacebookShareButton
                url={url}
                hashtag={convertToSnakeCase(title)}
              >
                <FacebookIcon round size={45} />
              </FacebookShareButton>

              <span className="ms-2" />

              <EmailShareButton
                url={url}
                subject={`Shareable link of Artist: ${title}`}
                body={`Artist: ${title}`}
                separator={"\n\n"}
              >
                <EmailIcon round size={45} />
              </EmailShareButton>

              <span className="ms-2" />

              <LinkedinShareButton
                url={url}
                title={`Artist: ${title}`}
                summary={description}
              >
                <LinkedinIcon round size={45} />
              </LinkedinShareButton>

              <span className="ms-2" />

              <TwitterShareButton
                url={url}
                title={`Artist: ${title}`}
                hashtags={[convertToSnakeCase(title)]}
              >
                <TwitterIcon round size={45} />
              </TwitterShareButton>

              <span className="ms-2" />

              <TelegramShareButton url={url} title={`Artist: ${title}`}>
                <TelegramIcon round size={45} />
              </TelegramShareButton>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
