// export const PROD_URL = "https://artistcube-production.up.railway.app";
export const PROD_URL = "https://api.artist-cube.de";

export const SWITCH_URL = PROD_URL.includes("development")
  ? "https://gala-cube.vercel.app/"
  : "https://gala-cube.de/";

export type UserRole = "user" | "admin" | "supplier";

export const COLORS = [
  "#49dd83",
  "#f8a264",
  "#a085ff",
  "#ff85aa",
  "#adde45",
  "#3e6ba0",
  "#a01b3e",
  "#a06b3e",
  "#1ba03e",
  "#6b3ea0",
  "#1ba06b",
  "#a0a01b",
];

export const PRIVACY_POLICY_LINK =
  "https://www.iubenda.com/privacy-policy/61344666";
export const COOKIES_POLICY_LINK =
  "https://www.iubenda.com/privacy-policy/61344666/cookie-policy";
export const AGB_POLICY_LINK =
  "https://www.iubenda.com/nutzungsbedingungen/61344666";

export const supportedVideoExtensions = [
  ".mp4",
  ".mov",
  ".avi",
  ".wmv",
  ".flv",
  ".mkv",
  ".webm",
  ".mpeg",
  ".3gp",
  ".m4v",
];
