import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

interface AuthRouteProps {
  element: React.ReactElement;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ element }) => {
  const authContext = useContext(AuthContext);

  return !authContext?.user ? element : <Navigate to="/" />;
};

export default AuthRoute;
