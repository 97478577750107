import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import "./style.css";

export default function Impressum() {
  return (
    <>
      <Navbar />
      <div className="impressum">
        <div className="impressum-heading">Impressum</div>
        <p className="mt-4">
          Restless UG
          <br />
          Gärtnerstraße 19
          <br />
          20253 Hamburg
          <br />
          HRB:168917
        </p>
        <h5>Geschäftsführer</h5>
        <p>Gian-Luca von Oesen</p>
      </div>

      <div className="fixed-footer">
        <Footer />
      </div>
    </>
  );
}
