import { PROD_URL } from '../helpers/constants';
class ChatbotServiceProvider {
  async sendMessage(message: string, supplierUid: string, serviceUid: string, idToken: string, chatMessages: { role: string; content: string; }[]): Promise<string> {
    console.log(`Supplier UID: ${supplierUid} || Service UID: ${serviceUid}`);
    console.log(`The message is: ${message}`);

    try {
      const requestBody = {
        currentQuery: message,
        chatMessages: chatMessages,
        supplierUid: supplierUid,
        serviceUid: serviceUid
      };
      const response = await fetch(`${PROD_URL}/getResponse`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`, // Include Authorization header if needed
        }
      });

      console.log(`Response from the endpoint is: ${JSON.stringify(response,null,2)}`);

      if (response.ok) {
        const responseData = await response.json();
        return responseData.message;
      } else {
        console.error("Error sending message to chatbot", response);
        return "There was an error communicating with the chatbot.";
      }
    } catch (error) {
      console.error("Error sending message to chatbot", error);
      return "There was an error communicating with the chatbot.";
    }
  }
}
export default ChatbotServiceProvider;