import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import "./style.css";
import signupImg from "../../assests/images/signup.svg";
import { Link, useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { getAdditionalUserInfo, signInWithPopup } from "@firebase/auth";
import { auth, provider } from "../../utils/firebase.utils";
import axios from "axios";
import {
  AGB_POLICY_LINK,
  PRIVACY_POLICY_LINK,
  PROD_URL,
} from "../../helpers/constants";
import { toast } from "react-toastify";
import { UserData } from "./types";
import { AuthContext } from "../../context/AuthContext";

export default function Signup() {
  const authContext = useContext(AuthContext);
  const [tab1, setTab1] = useState("tab-active");
  const [tab2, setTab2] = useState("tab-inactive");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [agbsChecked, setAgbsChecked] = useState(false);
  const [googleSignup, setGoogleSignUp] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (authContext?.user) {
  //     navigate("/");
  //   }
  // }, [authContext]);

  const signUpWithGoogle = async () => {
    try {
      setFormLoading(true);
      const result = await signInWithPopup(auth, provider);
      const newUser = getAdditionalUserInfo(result)?.isNewUser;

      const { uid, displayName, email, phoneNumber, photoURL } = result.user;
      console.log("user");
      console.log(result.user);
      const user: UserData = {
        uid, // necessary for social account
        userName: displayName || "", // assuming displayName is the username
        email: email || "", // email
        phoneNo: phoneNumber || "", // phoneNo
        photoUrl: photoURL || "", // photoUrl
        role: tab1 === "tab-active" ? "user" : "supplier", // assuming UserRole is an enum with default value
        isVerified: false,
        isBanned: false,
        isDeleted: false,
        isActive: true,
        createDate: new Date(),
        updateDate: new Date(),
        lastActiveDateTime: new Date(),
        stripeAccountId: "",
        stripeCustomerId: "",
      };

      const idToken = await result.user.getIdToken();

      if (newUser) {
        user.lastActiveDateTime = new Date();
        setFormLoading(false);
        signUpSocial(user);
      } else {
        updateActiveTimeMember(result.user.uid, idToken);
        setGoogleSignUp(false);
        setFormLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      setFormLoading(false);
      setGoogleSignUp(false);
    }
  };

  const signUp = async () => {
    try {
      if (!name || !name.trim()) {
        toast.error("Name is required.");
        return;
      }
      if (!email || !email.trim()) {
        toast.error("Email is required.");
        return;
      }
      
      if (!password || !password.trim()) {
        toast.error("Password is required.");
        return;
      }
      
      if (!confirmPassword || !confirmPassword.trim()) {
        toast.error("Confirm Password is required.");
        return;
      }
      
      if (password.trim() !== confirmPassword.trim()) {
        toast.error("Password and Confirm Password must be the same.");
        return;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        toast.error("Invalid email format.");
        return;
      }

      setFormLoading(true);
      const user = {
        userName: name,
        email,
        password,
        role: tab1 === "tab-active" ? "user" : "supplier",
        isActive: true,
        createDate: new Date(),
        updateDate: new Date(),
        lastActiveDateTime: new Date(),
      };
      console.log("user");
      console.log(user);
      const response = await axios.post(`${PROD_URL}/account`, user);
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      const signupResponse = response.data;
      toast.success("Account created!");
      setTimeout(() => {
        navigate("/login", { state: signupResponse });
      }, 1000);
    } catch (error: any) {
      console.error("Error from signup", error);
      toast.error(error.response.data.error.description);
    } finally {
      setFormLoading(false);
    }
  };

  const signUpSocial = async (user: UserData) => {
    try {
      const response = await axios.post(`${PROD_URL}/social-account`, user);
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      const signupResponse = response.data;

      toast.success("Account created!");
      localStorage.setItem("artistCubeUserData", JSON.stringify(user));
      setTimeout(() => {
        navigate("/", { state: user });
      }, 1000);
    } catch (error: any) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setFormLoading(false);
    }
  };

  const updateActiveTimeMember = async (memberId: string, idToken: string) => {
    try {
      const updatedLastActiveDateTime = {
        lastActiveDateTime: new Date(),
      };
      // Set the Authorization header with the ID token
      const config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const response = await axios.put(
        `${PROD_URL}/member/${memberId}`,
        updatedLastActiveDateTime,
        config
      );
      const updatedUserData = response.data;
      console.log("updated user data", updatedUserData);
      if (response.status === 200) {
        console.log(updatedUserData);
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
        setFormLoading(false);
        navigate("/", { state: updatedUserData });
        // No need to navigate here as it is done after sign in
      }
    } catch (error: any) {
      setFormLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="d-flex">
        <div className="left-col">
          <div className="signup-section">
            <h3 className="mb-4">Sign Up</h3>
            <h5 className="mb-4">I am...</h5>

            <div className="d-flex tabs form-control">
              <div
                className={tab1}
                onClick={() => {
                  setTab1("tab-active");
                  setTab2("tab-inactive");
                }}
              >
                Customer
              </div>
              <div
                className={tab2}
                onClick={() => {
                  setTab1("tab-inactive");
                  setTab2("tab-active");
                }}
              >
                Supplier
              </div>
            </div>

            <div className="text-center">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  googleSignup ? signUpWithGoogle() : signUp();
                }}
              >
                <div className="mb-3">
                  <input
                    className="form-control textbox"
                    id="name"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required={googleSignup ? false : true}
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control textbox"
                    id="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={googleSignup ? false : true}
                  />
                </div>

                <div
                  className="d-flex align-items-center mb-3"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-control textbox"
                    id="password"
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required={googleSignup ? false : true}
                  />
                  {passwordVisible ? (
                    <VisibilityOffOutlinedIcon
                      className="eyeIcon clickable"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      className="eyeIcon clickable"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  )}
                </div>

                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-control textbox"
                    id="confirm-password"
                    placeholder="Confirm your password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required={googleSignup ? false : true}
                  />
                  {passwordVisible ? (
                    <VisibilityOffOutlinedIcon
                      className="eyeIcon clickable"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      className="eyeIcon clickable"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  )}
                </div>

                <div
                  className="form-group form-check mt-3"
                  style={{ textAlign: "left" }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input clickable"
                    id="agbs"
                    checked={agbsChecked}
                    onChange={(e) => setAgbsChecked(!agbsChecked)}
                    required
                  />
                  <label>
                    I read and agree with the{" "}
                    <a
                      className="terms-link"
                      href={AGB_POLICY_LINK}
                      target="_blank"
                    >
                      terms of conditions
                    </a>{" "}
                    and the{" "}
                    <a
                      className="terms-link"
                      href={PRIVACY_POLICY_LINK}
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </label>
                </div>

                <div style={{ marginTop: "35px" }}>
                  <button
                    type="submit"
                    className="form-control signup-btn"
                    disabled={formLoading}
                  >
                    {formLoading ? "Creating..." : "Create Account"}
                  </button>
                </div>
                <div className="mt-3 mb-2">or</div>
                <div className="mb-4">Continue with your account</div>

                <div className="d-flex btn-div">
                  <button
                    className="form-control google-btn"
                    type="submit"
                    onClick={() => {
                      setGoogleSignUp(true);
                    }}
                  >
                    <i className="fab fa-google me-2"></i>Google
                  </button>
                </div>
              </form>

              <div className="mt-4">
                Already have an account?{" "}
                <Link className="login-link" to="/login">
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="right-col">
          <img src={signupImg} width="100%" />
        </div>
      </div>
    </>
  );
}
