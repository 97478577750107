import fancyHalle from "../../assests/images/fancy_halle.png";
import restaurant from "../../assests/images/restaurant.png";
import garden from "../../assests/images/garden.png";
import bar from "../../assests/images/bar.png";
import graffiti from "../../assests/images/graffiti.jpeg";
import healthySnack from "../../assests/images/healthy_snack.png";
import dinner from "../../assests/images/dinner.png";
import { Task } from "gantt-task-react";

export const supplierData = [
  {
    image: fancyHalle,
    supplierName: "Fancy Halle",
    supplierDesc: "2200Pax, 3000qm, 500Seats",
    totalPrice: "85.800€",
    pricePerPerson: "39€",
    serviceType: "Location",
  },
  {
    image: restaurant,
    supplierName: "Restaurant",
    supplierDesc: "150Pax, 300qm, 80Seats",
    totalPrice: "6750€",
    pricePerPerson: "45€",
    serviceType: "Location",
  },
  {
    image: garden,
    supplierName: "Garden",
    supplierDesc: "750Pax, 1200qm, 300Seats",
    totalPrice: "26.250€",
    pricePerPerson: "35€",
    serviceType: "Location",
  },
  {
    image: bar,
    supplierName: "Bar",
    supplierDesc: "50Pax, 180qm, 20Seats",
    totalPrice: "2400€",
    pricePerPerson: "48€",
    serviceType: "Location",
  },
  {
    image: graffiti,
    supplierName: "Graffiti",
    supplierDesc: "50Pax",
    totalPrice: "2950€",
    pricePerPerson: "59€",
    serviceType: "TeamEvent",
  },
  {
    image: healthySnack,
    supplierName: "Healthy Snack",
    supplierDesc: "50Pax",
    totalPrice: "900€",
    pricePerPerson: "18€",
    serviceType: "Catering",
  },
  {
    image: dinner,
    supplierName: "Dinner",
    supplierDesc: "50Pax",
    totalPrice: "1700€",
    pricePerPerson: "34€",
    serviceType: "Catering",
  },
  {
    image: garden,
    supplierName: "Garden",
    supplierDesc: "750Pax, 1200qm, 300Seats",
    totalPrice: "26.250€",
    pricePerPerson: "35€",
    serviceType: "Location",
  },
];

export const otherSupplierData = [
  {
    image: dinner,
    supplierName: "Dinner",
    supplierDesc: "50Pax",
    totalPrice: "1700€",
    pricePerPerson: "34€",
    serviceType: "Catering",
  },
  {
    image: healthySnack,
    supplierName: "Healthy Snack",
    supplierDesc: "50Pax",
    totalPrice: "900€",
    pricePerPerson: "18€",
    serviceType: "Catering",
  },
  {
    image: graffiti,
    supplierName: "Graffiti",
    supplierDesc: "50Pax",
    totalPrice: "2950€",
    pricePerPerson: "59€",
    serviceType: "TeamEvent",
  },
  {
    image: bar,
    supplierName: "Bar",
    supplierDesc: "50Pax, 180qm, 20Seats",
    totalPrice: "2400€",
    pricePerPerson: "48€",
    serviceType: "Location",
  },
  {
    image: garden,
    supplierName: "Garden",
    supplierDesc: "750Pax, 1200qm, 300Seats",
    totalPrice: "26.250€",
    pricePerPerson: "35€",
    serviceType: "Location",
  },
  {
    image: restaurant,
    supplierName: "Restaurant",
    supplierDesc: "150Pax, 300qm, 80Seats",
    totalPrice: "6750€",
    pricePerPerson: "45€",
    serviceType: "Location",
  },
  {
    image: fancyHalle,
    supplierName: "Fancy Halle",
    supplierDesc: "2200Pax, 3000qm, 500Seats",
    totalPrice: "85.800€",
    pricePerPerson: "39€",
    serviceType: "Location",
  },
];

export const categoryNames = ["Location", "Team Event", "Catering"];

export const serviceOptions = {
  items: 1,
  loop: false,
  // margin: 0,
  dots: true,
  nav: false,
  mouseDrag: false,
  touchDrag: false,
};

export const tasks: Task[] = [
  {
    start: new Date(2020, 1, 1, 9, 0), // 8:00 AM
    end: new Date(2020, 1, 1, 20, 30), // 12:00 PM
    name: "Service 1",
    id: "service1",
    type: "task",
    progress: 100,
    isDisabled: true,
    styles: {
      progressColor: "#41c575",
      progressSelectedColor: "#41c575",
      backgroundColor: "var(--primary-color)",
      backgroundSelectedColor: "var(--primary-color)",
    },
  },
  {
    start: new Date(2020, 1, 1, 10, 0), // 10:00 AM
    end: new Date(2020, 1, 1, 13, 0), // 3:00 PM
    name: "Service 2",
    id: "service2",
    type: "task",
    progress: 100,
    isDisabled: true,
    styles: {
      progressColor: "#fd8127",
      progressSelectedColor: "#fd8127",
      // backgroundColor: "#f8a264",
      // backgroundSelectedColor: "#f8a264",
    },
  },
  {
    start: new Date(2020, 1, 1, 12, 0), // 10:00 AM
    end: new Date(2020, 1, 1, 18, 0), // 3:00 PM
    name: "Service 3",
    id: "service3",
    type: "task",
    progress: 100,
    isDisabled: true,
    styles: {
      progressColor: "#7756ec",
      progressSelectedColor: "#7756ec",
      backgroundColor: "#a085ff",
      backgroundSelectedColor: "#a085ff",
    },
  },
  {
    start: new Date(2020, 1, 1, 9, 0), // 10:00 AM
    end: new Date(2020, 1, 1, 14, 0), // 3:00 PM
    name: "Service4",
    id: "service4",
    type: "task",
    progress: 100,
    isDisabled: true,
    styles: {
      progressColor: "#ff6291",
      progressSelectedColor: "#ff6291",
      backgroundColor: "#ff85aa",
      backgroundSelectedColor: "#ff85aa",
    },
  },
  {
    start: new Date(2020, 1, 1, 10, 0), // 10:00 AM
    end: new Date(2020, 1, 1, 17, 0), // 3:00 PM
    name: "Service 5",
    id: "service5",
    type: "task",
    progress: 100,
    isDisabled: true,
    styles: {
      progressColor: "#adde45",
      progressSelectedColor: "#adde45",
      backgroundColor: "#c8f864",
      backgroundSelectedColor: "#c8f864",
    },
  },
];

export const serviceSettings = {
  arrows: false,
  infinite: false,
  // speed: 500,
  slidesToShow: 3,
  swipeToSlide: true,
  // draggable: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        // draggable: false,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        // draggable: true,
        initialSlide: 0,
      },
    },
  ],
};

export const supplierSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  // speed: 500,
  slidesToShow: 1,
  swipeToSlide: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        initialSlide: 0,
      },
    },
  ],
};

export const supplierOptions = {
  items: 1,
  loop: false,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  dots: true,
  nav: true,
};
