import React from 'react';
import ChatBox from '../../components/chatbox'; // Import the ChatBox component

const TestChat: React.FC = () => {

  return (
    <div>
      <h2>Test Chat</h2>
      <ChatBox serviceUid='hehe' supplierUid='new'/> {/* Include the ChatBox component */}
    </div>
  );
};

export default TestChat;