import React, { createContext, useState, useContext, useEffect } from "react";
import EventProvider from "../providers/eventProvider";
import { Events } from "../screens/my-events/types";
import { useAuth } from "./AuthContext";

interface EventContextType {
  supplierEvents: Events[];
  fetchEventsOfSuppliers: (idToken: string) => void;
  updateEventStatus: (
    eventId: string,
    eventServiceId: string,
    eventStatus: string
  ) => void;
  // updateEvent: (updatedEvent: Events) => void;
}

// Create the context
const EventsContext = createContext<EventContextType | undefined>(undefined);

// Custom hook to access the services context
export const useEvents = () => {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error("useServices must be used within a EventsProvider");
  }
  return context;
};

// Provider component to wrap your application and provide the services data
export const EventsProvider = ({ children }: any) => {
  const [supplierEvents, setSupplierEvents] = useState<Events[]>([]);
  const { user } = useAuth();

  // Fetch events from the API
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const retrievedIdToken = await user?.getIdToken();
    if (retrievedIdToken) {
      fetchEventsOfSuppliers(retrievedIdToken);
    } else {
      console.log("unable to retreive token");
    }
  };

  const fetchEventsOfSuppliers = async (idToken: string) => {
    try {
      const eventProvider = new EventProvider();
      const events = await eventProvider.getSupplierEvents(idToken); // Pass idToken here
      if (events !== null) {
        console.log("Fetching supplier events");
        setSupplierEvents(events);
      } else {
        console.error("Error fetching events");
      }
    } catch (error) {
      console.error("Error fetching events", error);
    }
  };

  const updateEventStatus = async (
    eventId: string,
    eventServiceId: string,
    eventServiceStatus: string
  ) => {
    // Create a new array with the updated event data
    const updatedEvents = supplierEvents.map((event) => {
      // Check if the eventId matches the specified eventId
      if (event.eventId === eventId) {
        // Create a new array with the updated event services
        const updatedEventServices = event.eventServices.map((eventService) => {
          // Check if the eventServiceId matches the specified eventServiceId
          if (eventService.eventServiceId === eventServiceId) {
            // Update the eventServiceStatus
            return { ...eventService, eventServiceStatus: eventServiceStatus };
          }
          return eventService; // Return the event service unchanged if not the one to be updated
        });
        // Return the event with the updated event services
        return { ...event, eventServices: updatedEventServices };
      }
      return event; // Return the event unchanged if not the one to be updated
    });

    // Update the supplierEvents state with the updated events
    setSupplierEvents(updatedEvents);
  };

  return (
    <EventsContext.Provider
      value={{ supplierEvents, fetchEventsOfSuppliers, updateEventStatus }}
    >
      {children}
    </EventsContext.Provider>
  );
};
